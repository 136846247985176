// src/App.js
import React from 'react';
import './App.css';
import HomePage from './Home';
import { ThemeProvider } from './ThemeContext'; // Importer le ThemeProvider

function App() {
    return (
        <ThemeProvider>
            <div className="App">
                <HomePage />
            </div>
        </ThemeProvider>
    );
}

export default App;
