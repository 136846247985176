import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

const ContactInfo = () => {
    return (
        <div className="bg-gray-100 py-8">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
                <h2 className="text-3xl font-bold text-gray-800">Contactez-nous directement</h2>
                <div className="flex flex-col sm:flex-row justify-center items-center mt-6 space-y-4 sm:space-y-0 sm:space-x-8">

                    <div className="flex items-center">
                        <FontAwesomeIcon icon={faEnvelope} className="text-green-500 text-2xl mr-3" />
                        <span className="text-lg text-gray-700">direction@lou-ann.fr</span>
                    </div>

                    <div className="flex items-center">
                        <FontAwesomeIcon icon={faPhone} className="text-green-500 text-2xl mr-3" />
                        <span className="text-lg text-gray-700">07.86.63.97.79</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactInfo;
