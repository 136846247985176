import React, { useState } from 'react';


const ContactForm = () => {
    // État pour les champs du formulaire
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });

    // État pour les erreurs de validation
    const [errors, setErrors] = useState({});

    // État pour l'état d'envoi
    const [isSubmitted, setIsSubmitted] = useState(false);

    // Gestion des changements dans les champs
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Validation du formulaire
    const validateForm = () => {
        let formErrors = {};

        if (!formData.name.trim()) {
            formErrors.name = "Le nom est requis";
        }
        if (!formData.email.trim()) {
            formErrors.email = "L'email est requis";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            formErrors.email = "L'email n'est pas valide";
        }
        if (!formData.message.trim()) {
            formErrors.message = "Le message ne doit pas être vide";
        }

        console.log('Form Errors:', formErrors); // Vérifiez les erreurs ici

        return formErrors;
    };

    // Gestion de la soumission du formulaire
    const handleSubmit = (e) => {
        e.preventDefault(); // Empêche la redirection par défaut

        const formErrors = validateForm();

        if (Object.keys(formErrors).length === 0) {
            setIsSubmitted(true);
            console.log('Form Data:', formData);

            // Logique d'envoi ici (API, email service, etc.)

            // Réinitialisation du formulaire et des erreurs
            setFormData({
                name: '',
                email: '',
                phone: '',
                subject: '',
                message: ''
            });
            setErrors({});
        } else {
            setErrors(formErrors);
        }
    };

    return (
        <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-3xl font-bold text-gray-800 text-center mb-6">Contactez-nous</h2>

            {isSubmitted ? (
                <div className="bg-green-100 text-green-700 p-4 rounded">
                    Merci pour votre message ! Nous vous contacterons sous peu.
                </div>
            ) : (
                <form onSubmit={handleSubmit} className="space-y-6">
                    {/* Nom */}
                    <div>
                        <label className="block text-gray-700 font-semibold mb-2" htmlFor="name">Nom complet</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            className={`w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 ${errors.name ? 'border-red-500' : 'border-gray-300'}`}
                            placeholder="Votre nom"
                        />
                        {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
                    </div>

                    {/* Email */}
                    <div>
                        <label className="block text-gray-700 font-semibold mb-2" htmlFor="email">Adresse email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            className={`w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 ${errors.email ? 'border-red-500' : 'border-gray-300'}`}
                            placeholder="Votre email"
                        />
                        {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
                    </div>

                    {/* Téléphone */}
                    <div>
                        <label className="block text-gray-700 font-semibold mb-2" htmlFor="phone">Numéro de téléphone (optionnel)</label>
                        <input
                            type="text"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 border-gray-300"
                            placeholder="Votre numéro de téléphone"
                        />
                    </div>

                    {/* Sujet */}
                    <div>
                        <label className="block text-gray-700 font-semibold mb-2" htmlFor="subject">Sujet</label>
                        <input
                            type="text"
                            id="subject"
                            name="subject"
                            value={formData.subject}
                            onChange={handleInputChange}
                            className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 border-gray-300"
                            placeholder="Sujet du message"
                        />
                    </div>

                    {/* Message */}
                    <div>
                        <label className="block text-gray-700 font-semibold mb-2" htmlFor="message">Message</label>
                        <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleInputChange}
                            className={`w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 ${errors.message ? 'border-red-500' : 'border-gray-300'}`}
                            rows="5"
                            placeholder="Votre message"
                        ></textarea>
                        {errors.message && <p className="text-red-500 text-sm mt-1">{errors.message}</p>}
                    </div>

                    {/* Bouton d'envoi */}
                    <button
                        type="submit"
                        className="w-full py-3 bg-green-500 text-white font-semibold rounded-lg hover:bg-green-700 transition duration-300"
                    >
                        Envoyer le message
                    </button>
                </form>
            )}
        </div>
    );
};
export default ContactForm;
