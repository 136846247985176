import React, { useState, useRef } from 'react';
import { useTheme } from './ThemeContext'; // Assurez-vous que le chemin est correct
import logoLight from './assets/logo-light.png'; // Logo pour le mode clair
import logoDark from './assets/logo-dark.png'; // Logo pour le mode sombre
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faServer, faShieldAlt, faTools } from '@fortawesome/free-solid-svg-icons';
import Modal from './Modal'; // Assurez-vous que le chemin est correct
import ContactInfo from './ContactInfo';
import Head from './components/Head';

const HomePage = () => {
    const contactRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { isDarkMode, toggleDarkMode } = useTheme(); // Utiliser le hook pour accéder au thème

    // Fonction pour scroller vers la section contact
    const scrollToContact = () => {
        contactRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return (
        <div className={`min-h-screen transition-colors duration-50 ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-800'}`}>
            <Head
                title="Lou-Ann Technologies - Infogérance, Sécurité Informatique, Support Technique"
                description="Découvrez nos services d'infogérance, sécurité informatique et support technique pour des solutions innovantes. Contactez Lou-Ann Technologies pour plus d'informations."
            />
            {/* Header Section */}
            <header className={`header w-full shadow transition-colors duration-50 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'}`}>
                <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex items-center justify-between">
                    <div className="text-center flex-grow">
                        <img
                            src={isDarkMode ? logoDark : logoLight}
                            alt="Lou-Ann Technologies"
                            className="h-20 mx-auto transition-opacity duration-50"
                        />
                        <p className="mt-2">Solutions innovantes pour votre infrastructure IT.</p>
                    </div>
                    {/* Interrupteur */}
                    <div className="flex items-center">
                        <span className="mr-2 text-sm transition-colors duration-50">{isDarkMode ? 'Dark' : 'Light'}</span>
                        <label className="switch small">
                            <input type="checkbox" checked={isDarkMode} onChange={toggleDarkMode} />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
            </header>

            {/* Services Section */}
            <section className={`mt-12 py-12 transition-colors duration-50 ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="text-3xl font-bold text-center">{'Nos Services'}</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
                        <div className={`p-6 rounded-lg shadow-lg text-center transform transition-transform ease-in-out hover:scale-105 transition-colors duration-50 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'}`}>
                            <FontAwesomeIcon icon={faServer} className={`text-4xl mb-4 ${isDarkMode ? 'text-green-400' : 'text-green-500'}`} />
                            <h3 className="text-2xl font-semibold">Infogérance</h3>
                            <p className="mt-4">
                                Gestion complète de vos systèmes d'information, pour une infrastructure stable et performante.
                            </p>
                        </div>
                        <div className={`p-6 rounded-lg shadow-lg text-center transform transition-transform ease-in-out hover:scale-105 transition-colors duration-50 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'}`}>
                            <FontAwesomeIcon icon={faShieldAlt} className={`text-4xl mb-4 ${isDarkMode ? 'text-green-400' : 'text-green-500'}`} />
                            <h3 className="text-2xl font-semibold">Sécurité Informatique</h3>
                            <p className="mt-4">
                                Protégez vos données critiques avec nos solutions de sécurité avancées.
                            </p>
                        </div>
                        <div className={`p-6 rounded-lg shadow-lg text-center transform transition-transform ease-in-out hover:scale-105 transition-colors duration-50 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'}`}>
                            <FontAwesomeIcon icon={faTools} className={`text-4xl mb-4 ${isDarkMode ? 'text-green-400' : 'text-green-500'}`} />
                            <h3 className="text-2xl font-semibold">Support Technique</h3>
                            <p className="mt-4">
                                Un support réactif et disponible 24/7 pour tous vos besoins techniques.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Pourquoi nous choisir ? */}
            <section className={`mt-12 py-12 transition-colors duration-50 ${isDarkMode ? 'bg-gray-800' : 'bg-gray-200'}`}>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="text-3xl font-bold text-center">{'Pourquoi nous choisir ?'}</h2>
                    <p className="text-center mt-4 text-lg">
                        Chez Lou-Ann Technologies, nous mettons l'innovation et la satisfaction client au cœur de notre mission.
                    </p>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
                        <div className={`p-6 rounded-lg shadow-lg text-center transform transition-transform ease-in-out hover:scale-105 transition-colors duration-50 ${isDarkMode ? 'bg-gray-700' : 'bg-white'}`}>
                            <h3 className="text-xl font-semibold">Innovation</h3>
                            <p className="mt-4">
                                Nous utilisons les technologies les plus récentes pour garantir que vous ayez toujours un coup d'avance.
                            </p>
                        </div>
                        <div className={`p-6 rounded-lg shadow-lg text-center transform transition-transform ease-in-out hover:scale-105 transition-colors duration-50 ${isDarkMode ? 'bg-gray-700' : 'bg-white'}`}>
                            <h3 className="text-xl font-semibold">Support Premium</h3>
                            <p className="mt-4">
                                Un support réactif et disponible pour vous aider à tout moment.
                            </p>
                        </div>
                        <div className={`p-6 rounded-lg shadow-lg text-center transform transition-transform ease-in-out hover:scale-105 transition-colors duration-50 ${isDarkMode ? 'bg-gray-700' : 'bg-white'}`}>
                            <h3 className="text-xl font-semibold">Sécurité</h3>
                            <p className="mt-4">
                                Protégez vos données avec des solutions de sécurité robustes.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className={`mt-12 py-12 ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'}`}>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="text-3xl font-bold text-center">Ce que disent nos clients</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
                        <div className={`p-6 rounded-lg shadow-lg text-center transform transition-transform ease-in-out hover:scale-105 transition-colors duration-50 bg-white ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
                            <p className="italic">
                                "Lou-Ann Technologies a transformé notre infrastructure IT. Leur support est exceptionnel et leurs solutions sont toujours à la pointe de la technologie."
                            </p>
                            <p className="mt-4 font-semibold">— Client Satisfait</p>
                        </div>
                        <div className={`p-6 rounded-lg shadow-lg text-center transform transition-transform ease-in-out hover:scale-105 transition-colors duration-50 bg-white ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
                            <p className="italic">
                                "Une équipe dédiée et réactive qui a su répondre à toutes nos attentes. Nous recommandons vivement leurs services."
                            </p>
                            <p className="mt-4 font-semibold">— Autre Client</p>
                        </div>
                        <div className={`p-6 rounded-lg shadow-lg text-center transform transition-transform ease-in-out hover:scale-105 transition-colors duration-50 bg-white ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
                            <p className="italic">
                                "Les solutions proposées par Lou-Ann Technologies sont innovantes et nous ont permis de grandement améliorer notre sécurité informatique."
                            </p>
                            <p className="mt-4 font-semibold">— Client Heureux</p>
                        </div>
                        {/* Ajoutez d'autres cartes de témoignages ici */}
                    </div>
                </div>
            </section>


            {/* Contact Information Section */}
            <ContactInfo ref={contactRef} />  {/* Ajoutez cette ligne ici pour afficher les coordonnées */}

            {/* Footer */}
            <footer className={`text-white py-6 text-center mt-12 ${isDarkMode ? 'bg-gray-800' : 'bg-gray-800'}`}>
                <p>&copy; 2024 Lou-Ann Technologies. Tous droits réservés.</p>
            </footer>

            {/* Modal */}
            <Modal isOpen={isModalOpen} onClose={closeModal} />
        </div>
    );
};

export default HomePage;
