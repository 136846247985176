// src/components/Head.js
import React from 'react';
import { Helmet } from 'react-helmet';

const Head = ({ title, description }) => (
    <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content="infogérance, sécurité informatique, support technique, Lou-Ann Technologies" />
        <meta name="author" content="Lou-Ann Technologies" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content="/assets/logo-light.png" />
        <meta property="og:url" content="http://www.lou-ann.fr" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content="/assets/logo-light.png" />
    </Helmet>
);

export default Head;
